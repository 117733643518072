<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="選んでください" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		<h2>「ホイッスル」ライブ ブロードキャスト契約</h2>
		<p>「ホイッスル ライブ ブロードキャスト契約」は、Pix Technology PTE. LTD. (以下「当社」といいます) とお客様 (お客様は自然人、法人、またはその他の組織です)
			との間の権利を規定する契約です。法的拘束力のある電子契約書（以下「本契約書」といいます）。
			「同意する」にチェックを入れるか、「この契約を読み、これに従う」ボタンをクリックすると、本契約のすべての条項を注意深く読み、完全に理解し、無条件で完全に同意したことになります。 
		</p>
		<h3>Uni Live ユーザー配信基本ルール</h3>
		<p>
			配信コンテンツ
		</p>
		<p>
			1. 違法および有害情報  
			政治的に敏感な話題、民族分裂、宗教的過激主義に関するコンテンツの拡散を禁止します。  
			暴力、流血、恐怖、ギャンブル、薬物、またはその他の違法行為を含む内容の配信は禁止されています。  
			迷信の助長（例：占い、予言）は慎重に扱い、過度な宣伝を避けてください。  
		</p>
		<p>
			2. ポルノおよび低俗なコンテンツ  
			ヌード、性的な暗示、低俗なパフォーマンス、またはあらゆる形態のポルノコンテンツは禁止されています。  
			衣服は過度に露出してはいけません（例：胸や臀部の露出、透けた衣服など）。違反すると配信が中断される可能性があります。  
			性的な話題に関する挑発的な言動は禁止されています。  
		</p>
		<p>
			3. 他者の権利の侵害  
			許可なく他人の個人情報（氏名、住所など）を公開することは禁止されています。  
			ネットいじめ、悪意のある攻撃、または誹謗中傷は固く禁止されています。  
		</p>
		<p>
			4. 詐欺および虚偽行為  
			デマ、虚偽の広告、または未確認の情報（例：健康製品の効果の誇張）を拡散することは禁止されています。  
			詐欺、ねずみ講、またはユーザーに対し個人的な取引を誘導する行為は禁止されています。  
		</p>
		<p>
			5. 未成年者に関するコンテンツ  
			未成年者を厳重に保護する必要があります。子どもが配信に参加することは禁止されています。  
			未成年の心身の健康に害を及ぼす可能性のあるコンテンツの表示は禁止されています。  
		</p>

		<h3>Uni Live 動画アップロード基本ルール</h3>
		<p>
			コンテンツガイドライン（コミュニティ規範）
		</p>
		<p>
			禁止される暴力的コンテンツ：暴力、傷害、虐待、テロ関連のコンテンツ（実際のもの、またはシミュレーションを含む）のアップロードは禁止されています。  
		</p>
		<p>
			禁止されるポルノ・露骨なコンテンツ：ヌード、性的な暗示、ポルノ、またはあらゆる形態の成人向けコンテンツは禁止されています。  
		</p>

		<p>
			禁止されるヘイトスピーチ：人種、宗教、性別、性的指向、国籍などのグループに対する差別的、攻撃的、または扇動的なコンテンツの投稿は禁止されています。  
		</p>
		<p>
			禁止されるいじめ・嫌がらせ：他人を侮辱、脅迫、または嫌がらせするコンテンツ（未成年に対する不適切な行為を含む）のアップロードは禁止されています。  
		</p>
		<p>
			禁止される危険行為：怪我につながる可能性のある危険なチャレンジ、いたずら、または違法行為（例：薬物使用、武器の扱い）を示す動画のアップロードは禁止されています。  
		</p>



		<h3>第 1 条 一般規定</h3>
		<p>1. UniLive プラットフォームとは、Pix Technology PTE. LTD. およびその関連会社 (Pix Technology PTE. LTD. など)
			が所有するライブ ブロードキャスト プラットフォームを指します。 当社の登録要件および規則に従って、お客様は、当社の合法的に運営されている UniLive プラットフォーム (以下「プラットフォーム」といいます)
			上で当社のライブブロードキャストサービスプロバイダー (または「ライブブロードキャスト当事者」)
			になることを申請し、当社のプラットフォームユーザーにライブ用のコンテンツを提供します。オンラインライブ映像コンテンツの放送サービスにおいて、お客様が当社プラットフォーム上でサービスを提供する期間は、契約期間内とみなされます。
			当社は、お客様が参加、編集、制作した上記のアップロードされたビデオ コンテンツを事前に確認することはなく、また、そのようなビデオを率先して編集、整理、修正、または処理することもありません。 </p>
		<p>2. 本契約に署名する前に、あなたは当社の規則と要件を十分に理解しており、本契約に規定されているライブブロードキャスト当事者の責任と義務を履行する資格、能力、資格を有しています。
			本契約は、お客様に対する有効で拘束力のある強制力のある法的義務を構成します。お客様は、本契約に基づくすべての用語と定義を明確に認識しており、それについて何の疑問も抱いていません。 </p>
		<p>3. お客様は、当社にサービスを提供する際に現地法の関連規定を遵守することを約束し、宣言するものとし、本契約の履行の名目で中国およびその地域の法律に違反するその他の行為を行ってはならないものとします。 。 </p>
		<p>4. お客様と当社は、労働法レベルで雇用、労働、または役務関係を構築するものではなく、当社はお客様に社会保険料や福利厚生を支払う必要はありません。 </p>
		<p>5. お客様は、事前の書面による同意がない限り、サードパーティの競争プラットフォーム上でライブ ブロードキャスト関連の活動に従事してはなりません。 </p>
		<p>6. UniLive アンカーとして登録する前、または UniLive プラットフォームでライブ ブロードキャスト サービスを実行する前に、サードパーティ プラットフォームとの間で有効な独占的なライブ
			ブロードキャスト契約に署名していないこと、または有効な既存のライブ ブロードキャスト契約を締結していないことを確認したものとします。 </p>
		<p>7. 両当事者間の協力中、コンテンツのライブブロードキャスト、アップロード、公開、または送信に対するお客様の権利は、「UniLive ユーザーサービス契約」の条項に従うものとします。 </p>

		<h3>第 2 条 当社の権利と義務</h3>
		<p>1.
			当社は、プラットフォーム運営システムおよびライブ配信パーティーの管理規則を策定し、本規約の一部とする権利を有し、お客様を管理および監督する権利を有し、また、運用条件に基づいて対応するルールを調整または変更する場合は、これを理解し、同意するものとします。
		</p>
		<p>2. 当社は、お客様に対する賞与や罰則を設ける（取り消す）ために、お客様を検査および評価する権利を有しており、具体的な検査項目および基準は、お客様の追加の同意を得ることなく、当社が別途定めるものとします。
			当社が別途、正式な生放送協力協定の締結を要求した場合、お客様はいかなる方法でも署名を拒否または拒否してはなりません。[そうでない場合、当社に[50,000] (資本金: 5,000)
			元の一度限りの清算損害賠償金を支払うものとします。 ]。 </p>
		<p>3. 当社には、ライブ ブロードキャスト サービスを改善するための提案や意見を提出する権利があります。お客様は、当社の提案や意見を受け取ってから [3]
			営業日以内に対応する修正を行う必要があります。そうでない場合は、制限する権利があります。 UniLive ライブ ブロードキャスト ルームの使用を閉鎖、リサイクル、または終了します。お客様に支払われるサービス料金
			(該当する場合) からの対応する控除により、お客様に一定の損失が生じる可能性があり、その損失はお客様が負担するものとします。 。 </p>
		<p>4. 私たちは、ライブ配信に関連する事項について最終決定を下す最終的な権利を有します。 </p>
		<p>5. 当社は、お客様の名前 (本名、ペンネーム、スクリーンネーム、以前の名前、およびお客様の身元を表すテキスト記号を含みますがこれらに限定されません)、肖像画 (実在の人物の肖像画を含みますがこれらに限定されません)
			を使用する権利を有します。人物や漫画のポートレートなど）をプラットフォーム上でさまざまなプロモーションを実施するため。 </p>
		<p>6. 当社は、プラットフォームのテクニカル サービス サポートを提供する責任を負い、プラットフォーム サービス料金 (該当する場合) の決済にも責任を負います。 </p>

		<h3>第 3 条 お客様の権利と義務</h3>
		<p>1. 本物の身元情報と個人データを使用する必要があり、虚偽または不正な居住者身元情報、企業登録情報、または組織コード情報を登録と認証に使用してはなりません。 個人情報に変更があった場合は、速やかに更新してください。
			法定代理人は、未成年者によるライブ配信を禁止しており、本規約に定めるウェブキャスティングおよびライブ配信サービスを未成年者に提供させる場合には、法定後見人が当該サービスの内容が未成年者に適しているかどうかを法定後見人として判断し、法定後見人が一切の費用を負担しなければなりません。そこから生じる結果。
			お客様は、本契約を締結することにより、第三者の正当な利益を侵害しないことを約束します。本契約の受諾および履行は、お客様を拘束する法的文書に違反するものではなく、また、当社が第三者に対して責任を負うものでもありません。 </p>
		<p>2. ライブ ブロードキャストに必要なネットワーク、ビデオ、および音声のサポート機器はお客様の責任であり、ライブ ブロードキャストの画像が鮮明で、音声の品質がクリアで安定していることを保証します。 </p>
		<p>3. お客様は、プラットフォームが定めるライブ ブロードキャスト ルームの申請手順に従い、プラットフォームが要求する申請書類を提出し、対応する保証金を自発的に支払うことを約束します。 </p>
		<p>4. ライブ ブロードキャスト ルームはライブ ブロードキャストに使用する必要があり、ライブ ブロードキャスト以外のその他の活動には使用しないことを約束します。 <b>商品のライブ
				ストリーミングを実行する場合、お客様とサプライヤーは中国の法律を遵守し、当社が公布した「加盟店サービス契約」の関連規定を履行する必要があります。 </b></p>
		<p>5.
			本契約に基づいて実行する、および/またはこのプラットフォームで公開するすべての情報/情報/スピーチ/コンテンツには、中華人民共和国の関連する法律、規制および規定に違反するコンテンツが含まれてはなりません。国家の安全を脅かすコンテンツ、わいせつなコンテンツ、虚偽のコンテンツ、違法なコンテンツ、名誉毀損（商業的名誉毀損を含む）、違法な脅迫的または違法な嫌がらせ、他者の知的財産権、個人の権利、企業秘密、その他の正当な権利や利益を侵害するコンテンツに限定されます。公序良俗に反する、またはこれらのコンテンツを指すコンテンツへのリンク。
		</p>
		<p>6.
			お客様は、当社および当社プラットフォームのイメージを積極的に維持することを約束し、当社および/または当社プラットフォームのイメージや利益を損なう行為を行わないものとします。本契約の期間中および終了後は、同意した場合、お客様は、いかなるチャネル
			(ウェブサイト、ブログ、Weibo、WeChat、QQ チャット グループ、プレイヤーの集まりなどを含みますがこれらに限定されません)
			を通じて、当社および/または当社のプラットフォームに不利な発言をほのめかしたり公開したりしてはなりません。 </p>
		<p>7. 当社の書面による同意がない限り、いかなる場合でも、いかなる形式 (テキスト、口頭放送、ビデオ パッチなどを含むがこれらに限定されない)
			でサードパーティの競争プラットフォームの関連情報に言及してはなりません。当社のプラットフォームの既存ユーザー、他のライブブロードキャスト関係者、および当社の従業員を他のサードパーティの競合プラットフォームに参加させ、連絡支援や説得などの提供を含むがこれらに限定されない情報や便宜を提供することを案内または提供すること。
		</p>

		<h3>第 4 条 サービス料金と決済</h3>
		<p>1. あなたがプラットフォーム ユーザーにライブ ブロードキャスト サービスを提供することを前提として、ユーザーは消費用の仮想ギフトをあなたに与えることができ、また、あなたは当社の決済要件と規則に従って、決済関連収入
			(該当する場合) を申請することができます。当社は技術サービス料金の一定割合を請求し、対応する税金を差し引いた後にお客様にお支払いします。
			私たちは、異常な手段によって取得された仮想ギフトの消費を独立して判断し、処理する権利を有します。 </p>
		<p>2. お客様が受け取るサービス料金は、関連する国内法および規制に従って課税される必要があります。お客様が受け取ったサービス料金は、お客様がユーザー
			センターに記入した銀行口座に支払います。ログインして関連情報を照会できます。当社のプラットフォーム上の収益記録（決済データには税金データが含まれます）。 </p>
		<p>3. あなたが自然人であり、当社のプラットフォームに登録し、個人認証に合格した場合、当社は、あなたが受け取ったサービス料金を、ユーザー
			センターに記入した個人の銀行口座に支払う権利を有します。個人またはその他の組織が当社のプラットフォームに登録し、代理店認定に合格した場合、当社はお客様が受け取ったサービス料金を、お客様が代理店認定ページに記入した代理店アカウントに支払う権利を有しますが、お客様は有効な値を当社に提供する必要があります。当社が支払う前5営業日以内に、同額の追加税特別請求書（請求書名はライブ中継サービス料）を提出してください。請求書の提出が遅れて当社の支払いが遅れたとしても、規定違反にはなりません。私たちの側で契約します。
			当社がお客様が記入した口座に従ってサービス料金を支払ったとき、当社は本規約に定められた支払い義務を履行したものとみなされます。
			お客様が法人またはその他の団体の場合、アカウントおよびライブ放送室の管理および運営のためにスタッフまたはアンカーが負担した費用は、お客様とスタッフまたはアンカーの間で精算されます。
			料金の解決により紛争、訴訟、または補償が発生して当社に損失が生じた場合（スタッフまたはアンカーの給与を滞納した場合を含みますが、これに限定されません）、当社は以下の措置を講じます。事前に支払われるサービス料金を差し引く権利があり、当社はお客様から不足額を回収する権利を有します。
		</p>
		<p>4. お客様は、入力したアカウント情報が正しく、本物で、有効であることを保証します。アカウント情報が原因で当社側の支払いエラーが発生した場合は、お客様が単独で責任を負います。
			同時に、アカウント情報を変更する必要がある場合は、期限までに書面で当社に通知する必要があり、新しいアカウント情報は、申請書を提出し、当社による審査および承認の翌決済月から有効になります。 </p>

		<h3>第 5 条 機密保持制度</h3>
		<p>1. 当社の機密保持システムを厳格に遵守し、当社のビジネス秘密を無期限に保持することを約束してください。
			お客様が当社の営業秘密および情報を契約に違反して使用または開示し、当社の評判、名声、または経済に直接的または間接的に損失を被らせた場合、お客様は当社に 100,000
			元の清算損害賠償を補償するものとします。当社が損失を被った場合、お客様は当社の損失も補償するものとします。 </p>
		<p>2.
			営業秘密とは、当社が提供したもの、または両当事者間の協力中にお客様が知ったもの、または当社が第三者に対して機密保持義務を負うものを指します。これらは当社の事業に関連し、当社に経済的利益をもたらす可能性があります。技術情報、事業情報、当社の管理運営に関する情報および書類（本契約および関連契約の内容を含みます）、お客様の金額および決済方法を含む（ただしこれらに限定されません）実務上公知でない情報当社が取得する規格、権利所有方法、認可方法、顧客リスト、他のライブ配信者リスト、連絡先情報、サービス料金、スタッフリスト等は一般に知られることはありません。
		</p>
		<p>3. お客様は本契約を厳密に遵守し、当社の書面による許可または同意なしに当社のビジネス秘密を使用してはなりません。</p>
		<ul>
			<li>(1) 何らかの方法で第三者または不特定多数の人々に配布または漏洩すること。</li>
			<li>(2) 本契約の目的以外の目的で当社の企業秘密を使用すること。 </li>
		</ul>
		<p>4. 本契約終了後は、当社のすべての営業秘密を当社に返却するか、当社の監督下で当社の営業秘密を記録したすべての文書を破棄する必要があります。 </p>
		<p>5. 本条の規定は、本契約の終了後も有効に存続します。 </p>

		<h3>第 6 条 契約の変更、取り消しおよび終了</h3>
		<p>1. 当社は、必要に応じて本契約を変更、一時停止、または終了する権利を有し、関連するページで通知します。変更された契約が関連ページに公開されると、元の契約が事実上置き換えられます。
			本規約の変更後、お客様が当社のプラットフォームユーザーにライブブロードキャストおよびその他のライブブロードキャストサービスを提供し続ける場合、変更された規約を認識し、承諾したものとみなされます。
			当社が本契約に加えた変更に同意しない場合は、直ちに書面で当社に通知し、当社プラットフォーム上のライブブロードキャストサービスを停止する必要があります。 </p>
		<p>2. 両当事者は、本契約の終了について合意に達することにより、契約を終了することができます。 </p>
		<p>3. 以下のいずれかの状況がある場合、当社は事前の通知なしに本契約を直ちに終了することができます。</p>
		<ul>
			<li>(1) お客様が本契約の声明および約束に違反したことが判明しました。</li>
			<li>(2) お客様の行為は、直接的または間接的に当社の利益に重大な損害を与えます。</li>
			<li>(3)国内法および規制への違反</li>
			<li>(4) 本契約に規定されているその他の義務への違反</li>
			<li>(5) 消極的または不作為など、当社の要件を満たさない方法で本契約を履行し（契約違反に該当しない場合でも）、当社の通知後 10 日以内に修正を行わない。</li>
			<li>(6) 異常事態の発生により、当社はお客様が本契約に基づいてサービスを提供するのに適していないと判断し、通知後 10 日以内に異常事態が解消されなかった場合。</li>
			<li>(7) 業務調整のため、ライブ配信サービスの提供を中止させていただきます。 </li>
		</ul>
		<p>4. 本契約の第 6 条第 1 項および第 2 項に基づく契約の解除または終了に伴い、当社は本契約の第 4 条の規定に従ってお客様とのサービス料金を精算し、実際のサービス料金を支払います。当社のプラットフォームの時間ポリシー。
		</p>
		<p>5. 本契約の第 6 条第 3
			項に基づく契約の取消しまたは終了により、当社は、未決済のサービス料金をすべてお客様の口座から差し引く権利を有し、また、お客様に対し、以下の責任を負うよう要求する権利を有します。合意された契約違反。 </p>

		<h3>第 7 条 契約違反に対する責任</h3>
		<p>1.
			虚偽の発言または明示した約束への違反により、他の当事者が当社に対して訴訟や請求を起こしたり、当社の評判に損害を与えたりした場合、お客様は、当社が被るすべての直接的結果、および間接的な費用、損失、および損失を負担するものとします。訴訟に対して当社が支払った関連費用および弁護士費用を含む補償金。
		</p>
		<p>2.
			本契約で別段の合意がない限り、お客様が本契約の規定に違反した場合、またはお客様が提供するサービスが当社の要件を満たしていない場合、当社は以下を含むがこれに限定されない、対応する制限的または懲罰的な措置を一方的に講じる権利を有します。
			UniLiveライブ中継ルームの利用を制限、閉鎖、リサイクル、終了すること、個別サービス（ビデオライブ配信など）を制限または停止すること、および実際の状況に基づいて利用を再開するかどうかを決定すること、未決済のサービス料金をアカウントから差し引くこと。
		</p>
	</div>
</template>

<script>
export default {
	data() {
		return {
			options: [
				{
					value: '中文',
					label: '中文'
				}, {
					value: 'English',
					label: 'English'
				}, {
					value: '한국인',
					label: '한국인'
				}, {
					value: '日本語',
					label: '日本語'
				}, {
					value: 'Русский',
					label: 'Русский'//俄国
				}, {
					value: 'Tiếng Việt',
					label: 'Tiếng Việt'//越南
				}, {
					value: 'ภาษาไทย',
					label: 'ภาษาไทย'//泰国
				}
			],
			value: '',
			language: ''
		}
	},
	created() {
		// url获取参数
		this.language = this.$route.query.language;
		if (this.language == 'zh_CN') {
			this.$router.push({
				path: '/LiveAgreementZh'
			})
		}
		if (this.language == 'en_US') {
			this.$router.push({
				path: '/LiveAgreementEn'
			})
		}
		if (this.language == 'ko_KR') {
			this.$router.push({
				path: '/LiveAgreementKo'
			})
		}
		if (this.language == 'ja_JP') {
			this.$router.push({
				path: '/LiveAgreementJa'
			})
		}
		//俄语
		if (this.language == 'ru_RU') {
			this.$router.push({
				path: '/LiveAgreementRu'
			})
		}
		//越南语
		if (this.language == 'vi_VN') {
			this.$router.push({
				path: '/LiveAgreementVi'
			})
		}
		//泰语
		if (this.language == 'th_TH') {
			this.$router.push({
				path: '/LiveAgreementTh'
			})
		}
	},
	methods: {
		clickChange(value) {
			this.value = value;
			if (value == '中文') {
				this.$router.push({
					path: '/LiveAgreementZh'
				})
			}
			if (value == 'English') {
				this.$router.push({
					path: '/LiveAgreementEn'
				})
			}
			if (value == '한국인') {
				this.$router.push({
					path: '/LiveAgreementKo'
				})
			}
			if (value == '日本語') {
				this.$router.push({
					path: '/LiveAgreementJa'
				})
			}
			if (value == 'Русский') {
				this.$router.push({
					path: '/LiveAgreementRu'
				})
			}
			if (value == 'Tiếng Việt') {
				this.$router.push({
					path: '/LiveAgreementVi'
				})
			}
			if (value == 'ภาษาไทย') {
				this.$router.push({
					path: '/LiveAgreementTh'
				})
			}
		},
	}
}
</script>

<style>
.container {
	padding: 0.1rem 0.3rem;
}

.lang {
	text-align: right;
	margin-bottom: 0.3rem;
}

h1,
h2 {
	color: #333;
	font-size: 0.5rem;
}

h3 {
	margin-top: 20px;
	font-size: 0.4rem;
}

p {
	margin-bottom: 15px;
	font-size: 0.3rem;
}

li {
	font-size: 0.3rem;
}
</style>